function redirectToBasketWhenAddingMembership() {
	const component = document.querySelector('spektrix-memberships');

	if(component !== null) {
		component.addEventListener('success', function (e) {
			window.location.href = "/basket/";
		});
	}

	// // for local tests
	// component.addEventListener('fail', function (e) {
	// 	window.location.href = "/basket/";
	// });
}


$(() => {
	redirectToBasketWhenAddingMembership();
});
